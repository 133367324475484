import Timbr from "../assets/images/timbr_tn.jpg";
import Portfolio from "../assets/images/portfolio_tn.jpg";

const projectList  = [
    {
        id: 1,
        name: "Portfolio",
        img: Portfolio,
        url: '#',
        status: "In Progress",
    },
    {
        id: 2,
        name: "Timbr",
        img: Timbr,
        url: 'https://zmoodiedev.github.io/timbr/',
        status: "In Progress",
    },
    
]

export default projectList;